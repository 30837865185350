(function () {
	'use strict';

	angular
		.module('app')
		.directive('adminTools', [
			'seedcodeCalendar',
			'environment',
			adminTools,
		])
		.directive('subListResize', ['$window', '$timeout', subListResize])
		.directive('sourceInfo', ['environment', sourceInfo])
		.directive('validateSalesforceObject', [
			'environment',
			validateSalesforceObject,
		])
		.directive('salesforceFieldMapping', [
			'environment',
			salesforceFieldMapping,
		])
		.directive('salesforceResourceMapping', [
			'environment',
			salesforceResourceMapping,
		])
		.directive('filemakerjsFieldMapping', [
			'environment',
			filemakerjsFieldMapping,
		])
		.directive('filemakerFieldMapping', [
			'environment',
			filemakerFieldMapping,
		])
		.directive('googleFieldMapping', ['environment', googleFieldMapping])
		.directive('salesforceCustomFields', [
			'environment',
			salesforceCustomFields,
		])
		.directive('customActions', ['environment', customActions])
		.directive('validateSalesforceFieldmapping', [
			'environment',
			validateSalesforceFieldmapping,
		])
		.directive('validateFilemakerjsFieldmapping', [
			'environment',
			validateFilemakerjsFieldmapping,
		])
		.directive('validateFilemakerjsContacts', [
			'environment',
			validateFilemakerjsContacts,
		])
		.directive('validateFilemakerjsProjects', [
			'environment',
			validateFilemakerjsProjects,
		])
		.directive('validateFilemakerjsLayout', [
			'environment',
			'utilities',
			validateFilemakerjsLayout,
		])
		.directive('validateFilemakerjsTable', [
			'environment',
			'utilities',
			validateFilemakerjsTable,
		])
		.directive('testFilemakerOnline', ['environment', testFilemakerOnline])
		.directive('salesforceRelatedObjects', [
			'environment',
			salesforceRelatedObjects,
		])
		.directive('filemakerjsRelatedObjects', [
			'environment',
			filemakerjsRelatedObjects,
		])
		.directive('codeEditor', [
			'environment',
			'seedcodeCalendar',
			codeEditor,
		]);

	function adminTools(seedcodeCalendar, environment) {
		return {
			restrict: 'EA',
			scope: true,
			controller: function ($scope) {
				const config = seedcodeCalendar.get('config');

				// Assign object to group access if it doesn't exist so we can use this as state
				if (!config.groupAccess) {
					config.groupAccess = {};
				}

				$scope.props = {
					groupAccess: config.groupAccess,
					sfOrgID:
						fbk.settings?.sr?.context?.organization?.organizationId,
				};
			},

			templateUrl: environment.isPhone
				? 'app/mobile/settings/setting/admin-tools.html'
				: 'app/settings/setting/admin-tools.html',
		};
	}

	function subListResize($window, $timeout) {
		return {
			restrict: 'EA',
			link: function (scope, element, attributes) {
				var watchTarget;

				// Call window resize to initialize position
				initWatcher(true);

				function initWatcher() {
					var targetElement = $('.license-users-separator');
					if (!targetElement.length) {
						window.setTimeout(function () {
							initWatcher();
						}, 50);
						return;
					}

					angular.element($window).on('resize', resize);
					angular.element($window).on('orientationchange', resize);

					watchTarget = scope.$watch(
						function () {
							return targetElement.position().top;
						},
						function () {
							resize();
						}
					);

					function resize() {
						var targetTop = targetElement.position().top;
						var targetHeight = targetElement.outerHeight();
						if (targetTop > 0) {
							element.css('top', targetTop + targetHeight);
						}
					}
				}

				//Clean up external events on element destroy
				scope.$on('$destroy', function () {
					//Destroy watcher
					watchTarget();
				});
			},
		};
	}

	function processTransformedSourceItem(source, setting) {
		return typeof setting === 'function' ? setting(source) : setting;
	}

	function validateSalesforceFieldmapping(environment) {
		return {
			restrict: 'EA',
			scope: {
				sources: '=',
				selectedSource: '=',
				sourceTemplate: '=',
			},
			link: function (scope, element, attrs) {
				scope.testSalesForceFieldMapping = testSalesForceFieldMapping;
				function testSalesForceFieldMapping(callback) {
					scope.mappingTest = {};
					var object = scope.selectedSource.objectName;
					var index;
					var dataClean;
					if (!object) {
						return;
					}
					if (!scope.selectedSource.unusedMap) {
						scope.selectedSource.unusedMap = {};
					}

					var sourceMap = scope.sourceTemplate.fieldMap;
					var fieldMap = scope.selectedSource.fieldMap;
					var unusedMap = scope.selectedSource.unusedMap;
					var hideMap = scope.selectedSource.hideMap;
					var customFields = scope.selectedSource.customFields;
					var field;
					var message;
					var requiredFields = [];
					var fields = [];
					var titleUnmapped = false;

					//validate our required fields are mapped
					//validate our field mapping, e.g. unmapped fields should be marked as unused.
					for (field in sourceMap) {
						if (
							processTransformedSourceItem(
								scope.selectedSource,
								sourceMap[field].visible
							) &&
							!fieldMap[field]
						) {
							if (
								((field === 'resource' ||
									field === 'resourceID') &&
									scope.selectedSource
										.useAssignedResources) ||
								(field === 'allDay' &&
									!scope.selectedSource.allowAllDay) ||
								(field === 'resourceID' &&
									(!scope.selectedSource.relatedValueMap ||
										!scope.selectedSource.relatedValueMap
											.resourceID))
							) {
								//don't count this even if unmapped
							} else {
								if (field === 'title') {
									titleUnmapped = true;
								} else if (
									processTransformedSourceItem(
										scope.selectedSource,
										sourceMap[field].required
									)
								) {
									requiredFields.push(field);
								} else if (
									!unusedMap[field] &&
									!hideMap[field]
								) {
									fields.push(field);
								}
							}
						}
					}

					// Title field is not mapped
					if (titleUnmapped) {
						scope.mappingTest.mappingTested = true;
						message = `The Display field cannot be left blank. Please provide at least one field value to display so your events can show on the calendar`;
						scope.mappingTest.object = false;
						scope.mappingTest.details = message;
						return;
					}

					// Required fields that are not mapped
					if (requiredFields.length > 0) {
						scope.mappingTest.mappingTested = true;
						requiredFields = requiredFields.join(', ');
						message =
							'The following required fields are not mapped. Please map these fields to a valid Salesforce field for this object:' +
							'\n' +
							requiredFields;
						scope.mappingTest.object = false;
						scope.mappingTest.details = message;
						return;
					}

					// Regular fields that are not mapped but not marked as unused
					if (fields.length > 0) {
						scope.mappingTest.mappingTested = true;
						fields = fields.join(', ');
						message =
							'The following fields are not mapped, but are enabled. Please un-check enable or map the field to a valid Salesforce field for this object:' +
							'\n' +
							fields;
						scope.mappingTest.object = false;
						scope.mappingTest.details = message;
						return;
					}

					//validate our custom field mapping, e.g. unmapped fields should be marked as unused.
					for (var property in customFields) {
						if (!customFields[property].field) {
							fields.push(customFields[property].name);
						}
					}
					if (fields.length > 0) {
						scope.mappingTest.mappingTested = true;
						fields = fields.join(', ');
						message =
							'There are custom fields that have not been mapped to a valid Salesforce field.';
						scope.mappingTest.object = false;
						scope.mappingTest.details = message;
						return;
					}

					const requests = [];
					let requestOverride;
					if (
						scope.selectedSource.isMapOnly &&
						scope.selectedSource.queryOnGeocode
					) {
						if (scope.selectedSource.allowTextFieldMap?.geocode) {
							message =
								'When "limit to map boundary is selected" geocode cannot be mapped to a text field';
							scope.mappingTest.object = false;
							scope.mappingTest.details = message;
							scope.mappingTest.mappingTested = true;
							return;
						}
						// Geocode to check against (doesn't matter just needs to be something to query)
						const geocode = {
							lat: 48.01499557,
							lng: -122.06401062,
						};
						const geoFieldPrefix =
							scope.selectedSource.fieldMap.geocode?.substring(
								0,
								scope.selectedSource.fieldMap.geocode.length - 1
							);
						requestOverride = encodeURIComponent(
							`WHERE ( ${geoFieldPrefix}Latitude__s >= ${geocode.lat} AND ${geoFieldPrefix}Latitude__s <= ${geocode.lat} AND ${geoFieldPrefix}Longitude__s >= ${geocode.lng} AND ${geoFieldPrefix}Longitude__s <= ${geocode.lng} )`
						);
					} else {
						let start = moment();
						let end = moment().add(1, 'd');
						if (scope.selectedSource.allowAllDay) {
							start = start.format();
							end = end.format();
						} else {
							start = start.format('YYYY-MM-DD');
							end = end.format('YYYY-MM-DD');
						}

						requests.push({
							start: '< ' + start,
							end: '> ' + end,
						});
					}

					if (scope.selectedSource.sourceTypeID === 10) {
						sfApi.saveSchedules(scope.sources); // Do we need this?
						sfApi.findRecords(
							object,
							process,
							requests,
							scope.selectedSource.id,
							null,
							requestOverride
						);
					} else {
						fbk.saveSchedules(scope.sources); // Do we need this?
						fbk.findRecords(
							object,
							process,
							requests,
							scope.selectedSource.id,
							null,
							requestOverride
						);
					}

					function process(data) {
						scope.$evalAsync(function () {
							scope.mappingTest.mappingTested = true;
							if (data[0] && data[0].errorCode) {
								//failed
								index = data[0].message.indexOf('ERROR');
								dataClean = data[0].message.substring(index);
								scope.mappingTest.object = false;
								scope.mappingTest.details = dataClean;
								if (callback) {
									callback(false);
								}
							} else {
								//passed
								scope.mappingTest.object = true;
								scope.mappingTest.details =
									'Salesforce Response: No Errors';
								if (callback) {
									callback(true);
								}
							}
						});
					}
				}
			},
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-salesforce-fieldmap.html'
				: 'app/settings/source/validate-salesforce-fieldmap.html',
		};
	}

	function validateFilemakerjsFieldmapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-filemakerJS-fieldmap.html'
				: 'app/settings/source/validate-filemakerJS-fieldmap.html',
		};
	}

	function validateFilemakerjsContacts(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-filemakerJS-contacts.html'
				: 'app/settings/source/validate-filemakerJS-contacts.html',
		};
	}
	function validateFilemakerjsProjects(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-filemakerJS-projects.html'
				: 'app/settings/source/validate-filemakerJS-projects.html',
		};
	}

	function validateFilemakerjsTable(environment, utilities) {
		return {
			restrict: 'EA',
			scope: {
				tableName: '=',
				layoutName: '=',
				validTables: '=',
			},
			link: function (scope, element, attributes) {
				scope.testFileMakerTable = testFileMakerTable;

				function testFileMakerTable() {
					var tableName = scope.tableName;
					var layoutName = scope.layoutName;
					var validTables = scope.validTables;

					scope.tableTest = {};

					if (!validTables) {
						scope.tableTest.tableTested = true;
						scope.tableTest.table = false;
						scope.tableTest.details =
							'DayBack either had trouble retrieving table occurrences or cannot find any valid table occurrences in this file';
						return;
					}

					if (!layoutName) {
						scope.tableTest.tableTested = true;
						scope.tableTest.tablefalse;
						scope.tableTest.details = 'No Layout specified';
						return;
					}

					if (!tableName) {
						scope.tableTest.tableTested = true;
						scope.tableTest.table = false;
						scope.tableTest.details =
							'No Table Occurrence specified';
						return;
					}

					var validTable = false;
					for (var i = 0; i < validTables.length; i++) {
						if (validTables[i].id === tableName) {
							validTable = true;
						}
					}
					if (!validTable) {
						scope.tableTest.tableTested = true;
						scope.tableTest.table = false;
						scope.tableTest.details =
							'DayBack either had trouble finding this table occurrence or it is missing a required field';
						return;
					}

					var payload = {};
					payload.script = 'Validate Layout - DayBack';
					var requiredFields = [
						'DBk_JSON',
						'DBk_JSON_ListOf',
						'DBk_Expression',
						'DBk_TimestampStartCalcNum',
						'DBk_TimestampEndCalcNum',
					];
					var request = {};
					request.layout = layoutName;
					payload.request = request;
					var callbackId = utilities.generateUID();
					dbk_fmFunctions[callbackId] = processResult;
					payload.callback = callbackId;
					payload.dbk = true;
					utilities.fileMakerCall(payload);

					function processResult(data) {
						if (data.status === 200) {
							var table = data.result.table;
							var missingFields = [];
							required(requiredFields, data.result.fields);
							scope.$evalAsync(function () {
								if (!table) {
									scope.tableTest.tableTested = true;
									scope.tableTest.table = false;
									scope.tableTest.details =
										'DayBack cannot find the table ' +
										table;
								} else if (table !== tableName) {
									scope.tableTest.tableTested = true;
									scope.tableTest.table = false;
									scope.tableTest.details =
										'This table occurrence is not in the context of the specified layout';
								} else if (missingFields.length > 0) {
									//test failed
									scope.tableTest.tableTested = true;
									scope.tableTest.table = false;
									scope.tableTest.details =
										'DayBack cannot find the following required fields in the table occurrence ' +
										data.result.table +
										': ' +
										missingFields.join(', ');
								} else {
									scope.tableTest.tableTested = true;
									scope.tableTest.table = true;
									scope.tableTest.details =
										'FileMaker Response: No Errors';
								}
							});
						}

						function required(requiredFields, fields) {
							if (!fields) {
								return;
							}
							for (var i = 0; i < requiredFields.length; i++) {
								var result = false;
								for (var f = 0; f < fields.length; f++) {
									if (fields[f] === requiredFields[i]) {
										result = true;
										break;
									}
								}
								if (!result) {
									missingFields.push(requiredFields[i]);
								}
							}
						}
					}
				}
			},
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-filemakerJS-table.html'
				: 'app/settings/source/validate-filemakerJS-table.html',
		};
	}

	function validateFilemakerjsLayout(environment, utilities) {
		return {
			restrict: 'EA',
			scope: {
				validLayouts: '=',
				layoutName: '=',
				tableFunction: '=',
			},
			link: function (scope, element, attributes) {
				scope.testFileMakerLayout = testFileMakerLayout;

				function testFileMakerLayout() {
					var layout = scope.layoutName;
					var validLayouts = scope.validLayouts;
					var setFileMakerTable = scope.tableFunction;

					scope.layoutTest = {};

					if (!validLayouts) {
						scope.layoutTest.layoutTested = true;
						scope.layoutTest.layout = false;
						scope.layoutTest.details =
							'DayBack either had trouble retrieving layouts or cannot find any valid layouts in this file.';
						return;
					}

					if (!layout) {
						scope.layoutTest.layoutTested = true;
						scope.layoutTest.layout = false;
						scope.layoutTest.details = 'No Layout specified.';
						return;
					}

					var validLayout = false;
					for (var i = 0; i < validLayouts.length; i++) {
						if (
							validLayouts[i].id &&
							validLayouts[i].id === layout
						) {
							validLayout = true;
							break;
						}
					}
					if (!validLayout) {
						scope.layoutTest.layoutTested = true;
						scope.layoutTest.layout = false;
						scope.layoutTest.details =
							'DayBack cannot find the layout ' +
							layout +
							' in this file.';
						return;
					}

					var payload = {};
					payload.script = 'Validate Layout - DayBack';
					var requiredFields = [
						'DBk_JSON',
						'DBk_JSON_ListOf',
						'DBk_Expression',
						'DBk_TimestampStartCalcNum',
						'DBk_TimestampEndCalcNum',
					];
					var request = {};
					request.layout = layout;
					payload.request = request;
					var callbackId = utilities.generateUID();
					dbk_fmFunctions[callbackId] = processResult;
					payload.callback = callbackId;
					payload.dbk = true;
					utilities.fileMakerCall(payload);

					function processResult(data) {
						if (data.status === 200) {
							var table = data.result.table;
							var missingFields = [];
							required(requiredFields, data.result.fields);
							scope.$evalAsync(function () {
								if (!table) {
									scope.layoutTest.layoutTested = true;
									scope.layoutTest.layout = false;
									scope.layoutTest.details =
										'DayBack cannot find the layout ' +
										layout;
								} else if (missingFields.length > 0) {
									//test failed
									scope.layoutTest.layoutTested = true;
									scope.layoutTest.layout = false;
									scope.layoutTest.details =
										'DayBack cannot find the following required fields in the table occurrence ' +
										data.result.table +
										': ' +
										missingFields.join(', ');
								} else {
									scope.layoutTest.layoutTested = true;
									scope.layoutTest.layout = true;
									scope.layoutTest.details =
										'FileMaker Response: No Errors';
									var tableUpdate = {
										status: 200,
										result: table,
									};
									setFileMakerTable(tableUpdate);
								}
							});
						}

						function required(requiredFields, fields) {
							if (!fields) {
								return;
							}
							for (var i = 0; i < requiredFields.length; i++) {
								var result = false;
								for (var f = 0; f < fields.length; f++) {
									if (fields[f] === requiredFields[i]) {
										result = true;
										break;
									}
								}
								if (!result) {
									missingFields.push(requiredFields[i]);
								}
							}
						}
					}
				}
			},
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-filemakerJS-layout.html'
				: 'app/settings/source/validate-filemakerJS-layout.html',
		};
	}

	function testFilemakerOnline(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/filemaker-source-tests.html'
				: 'app/settings/source/filemaker-source-tests.html',
		};
	}

	function salesforceCustomFields(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/custom-fields.html'
				: 'app/settings/source/custom-fields.html',
		};
	}

	function sourceInfo(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/source-info.html'
				: 'app/settings/source/source-info.html',
		};
	}

	function salesforceRelatedObjects(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/related-salesforce.html'
				: 'app/settings/source/related-salesforce.html',
		};
	}

	function filemakerjsRelatedObjects(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/related-filemakerJS.html'
				: 'app/settings/source/related-filemakerJS.html',
		};
	}

	function customActions(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/custom-actions.html'
				: 'app/settings/source/custom-actions.html',
		};
	}

	function validateSalesforceObject(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/validate-salesforce-object.html'
				: 'app/settings/source/validate-salesforce-object.html',
		};
	}

	function salesforceFieldMapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/fieldmap-salesforce.html'
				: 'app/settings/source/fieldmap-salesforce.html',
		};
	}

	function salesforceResourceMapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/resourcemap-salesforce.html'
				: 'app/settings/source/resourcemap-salesforce.html',
		};
	}

	function filemakerjsFieldMapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/fieldmap-filemakerJS.html'
				: 'app/settings/source/fieldmap-filemakerJS.html',
		};
	}

	function filemakerFieldMapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/fieldmap-filemaker.html'
				: 'app/settings/source/fieldmap-filemaker.html',
		};
	}

	function googleFieldMapping(environment) {
		return {
			restrict: 'EA',
			templateUrl: environment.isPhone
				? 'app/settings/source/fieldmap-google.html'
				: 'app/settings/source/fieldmap-google.html',
		};
	}

	function codeEditor(environment, seedcodeCalendar) {
		return {
			require: 'ngModel',
			restrict: 'EA',
			link: function (scope, element, attrs, ngModel) {
				var config = seedcodeCalendar.get('config');
				//Uses the CodeMirror library: https://codemirror.net
				var unregister;

				var mode = attrs.mode ? attrs.mode : 'javascript';
				var height = attrs.editorHeight;

				//Exit if CodeMirror isn't loaded
				if (typeof CodeMirror === 'undefined') {
					return;
				}

				unregister = scope.$watch(function () {
					return ngModel.$modelValue;
				}, initialize);

				function initialize(value) {
					var editor = CodeMirror.fromTextArea(element[0], {
						theme: config.darkMode ? 'material' : 'default',
						value: value,
						mode: mode,
						htmlMode: true,
						lineNumbers: true,
						lineWrapping: true,
					});

					if (height) {
						editor.setSize('100%', height);
					}

					// Keep the ngModel in sync with changes from CodeMirror
					editor.on('change', function (instance) {
						var newValue = instance.getValue();
						if (newValue !== ngModel.$viewValue) {
							scope.$evalAsync(function () {
								ngModel.$setViewValue(newValue);
							});
						}
					});
					unregister();
				}
			},
		};
	}
})();
